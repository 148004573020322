@import 'mixins';

.site-header{
    width: 100%;
    height: 70px;
    // background-color: red;
    background-color: white;
    @include flexRow(center);
    // box-shadow:  0px 1px 5px 0px rgba(0, 0, 0, 0.205);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        @include flexRow(space-between);
        // background-color: yellow;
    }

    &__first-block{
        width: auto;
        height: 100%;
        // background-color: pink;
        @include flexRow(flex-start);
    }

    &__logo-block{
        width: 200px;
        height: 100%;
        // background-color: plum;
        @include flexColumn(center);
    }

    &__city-block{
        margin-left: 50px;
        height: 100%;
        // background-color: plum;
        text-decoration: none;
        @include flexRow(flex-start);
        align-items: center;
        color: black;
        font-size: 18px;
        cursor: pointer;

    }
    
    &__city-name{
        color: $main;
        font-weight: 500;
        margin-left: 10px;
        font-size: 20px;
        // background-color: orange;
        height: 100%;
        @include flexColumn(center);
        // position: relative;
    }

    &__nav{
        // background-color: pink;
    }

    &__menu{
        list-style: none;
        // background-color: plum;
        @include flexRow(space-between);
        height: 100%;
    }

    &__menu-item{
        margin: 0px 10px 0px 10px;
        // background-color: orange;
        @include flexColumn(center);
        // @include fle
    }

    &__menu-link{
        // background-color: aqua;
        padding: 10px;
        border-radius: 5px;
        text-decoration: none;
        color: black;
        border: 1px solid black;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: $main;
            color: white;
            border-color: $main;
        }
    }

    // @keyframes headerButtonAnim {
    //     0%{box-shadow: 0px 0px 0px 0px red;}
    //     50%{box-shadow: 0px 0px 0px 5px red;}
    //     55%{box-shadow: 0px 0px 0px 0px red;}
    // }

    @keyframes headerButtonAnim {
        0%{left: -50px;}
        50%{left: 210px;}
        100%{left: 210px;}
        // 100%{left: -50px;}
    }

    &__menu-link_extra{
        background-color: $main;
        color: white;
        border-color: $main;
        // animation: headerButtonAnim 1s infinite;
        // background-color: red;
        position: relative;
        overflow: hidden;
        &:hover{
            background-color: darken($main, 5%);
            border-color: darken($main, 5%);
        }
        &::before{
            animation: 2s headerButtonAnim infinite;
            content: "";
            display: block;
            width: 30px;
            height: 200px;
            background-color: white;
            position: absolute;
            // left: -50px;
            box-shadow: 3px 0px 3px 0px rgb(255, 255, 255);
            transform: rotate(15deg);
            top: -50px;
            left: -50px;
        }
    }
}

.auth-section{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.445);
    @include flexRow(center);
    align-items: center;
    z-index: 20;
    display: none;
    position: absolute;
    // background-color: red;

    &__auth-block{
        width: 300px;
        height: 500px;
        background-color: white;
        // background-color: yellow;
        border-radius: 5px;
        // overflow: hidden;
        @include flexColumn(flex-start);
        position: relative;
    }

    &__close-button{
        position: absolute;
        width: 30px;
        height: 30px;
        // background-color: red;
        @include flexRow(center);
        align-items: center;
        left: calc(100% + 5px);
        bottom: calc(100% + 5px);
        z-index: 1;
        svg{
            cursor: pointer;
            transition: 0.3s;
            path{
                fill: white;
            }
            &:hover{
                transform: rotate(90deg);
            }
        }
    }
    
    &__toggles-container{
        overflow: hidden;
        border-radius: 5px 5px 0px 0px;
        width: 100%;
        height: 50px;
        // background-color: red;
        background-color: $main;
        @include flexRow(flex-start);
    }

    &__toggle{
        width: 150px;
        height: 50px;
        // background-color: yellow;
        @include flexColumn(center);
        cursor: pointer;
        text-align: center;
        color: white;
        transition: 0.3s;
        &:hover{
            background-color: darken($main, 5%);
        }
    }

    &__toggle_active{
        background-color: darken($main, 5%);
        pointer-events: none;
        &:hover{
            // background-color: darken($main, 5%);
        }
    }

    &__forms-container{
        width: 300px;
        height: 450px;
        border-radius: 5px;
        overflow: hidden;
        // background-color: pink;
    }

    &__form{
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        height: 100%;
        // background-color: pink;
        @include flexColumn(flex-start);
    }

    &__form-header{
        margin-top: 20px;
        font-weight: 400;
        width: 100%;
        text-align: center;
        // background-color: plum;
        // margin-bottom: 10px;
    }

    &__inputs-container{
        margin-top: 30px;
        height: 310px;
        // background-color: plum;
        @include flexColumn(flex-start);
        align-items: center;
    }

    &__input{
        width: calc(100% - 11px);
        padding: 0px 0px 0px 5px;
        border: none;
        height: 35px;
        margin-top: 20px;
        outline: none;
        box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.199);
        border-radius: 3px;
        &:first-child{
            margin-top: 0px;
        }
    }

    &__error{
        // background-color: orange;
        margin-top: 10px;
        min-height: 40px;
        text-align: center;
    }

    &__button{
        border: none;
        width: 100%;
        height: 40px;
        margin-top: 10px;
        border-radius: 3px;
        background-color: $main;
        @include flexColumn(center);
        text-align: center;
        color: white;
        font-size: 18px;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: darken($main, 5%);
        }
    }
}

#modals-block{
    display: none;
}

.modal-button{
    cursor: pointer;
}

.modals-block{
    width: 100%;
    height: 100vh;
    // background-color: red;
    background-color: transparentize(black, 0.5);
    position: absolute;
    top: 0px;
    @include flexRow(center);
    z-index: 2;

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexColumn(center);
        align-items: center;
    }

    #consul-block{
        display: none;
    }

    &__modal-block{
        background-color: white;
        border-radius: 3px;
        padding: 20px;
        width: 260px;
        // height: 400px;
        // background-color: pink;
        position: relative;
        @include flexColumn(flex-start);
    }

    &__close-button{
        position: absolute;
        width: 30px;
        // height: 30px;
        // background-color: plum;
        @include flexColumn(center);
        align-items: center;
        left: calc(100% - 35px);
        bottom: calc(100% - 35px);
        cursor: pointer;
        transition: 0.3s;
        svg{
            transition: 0.3s;
            path{
                fill: $admin-blue;
            }
        }
        &:hover{
            svg{
                transform: rotate(180deg);
            }
        }
    }

    &__modal-header{
        width: 100%;
        // height: 40px;
        // background-color: plum;
        font-size: 26px;
    }

    &__form{
        width: 100%;
        // height: 300px;
        // background-color: plum;
        margin-top: 15px;
        @include flexColumn(flex-start);
        align-items: center;
    }

    &__input{
        width: 250px;
        height: 40px;
        padding: 0px 0px 0px 4px;
        border: none;
        outline: none;
        background-color: darken(white, 5%);
        box-shadow: 0px 2px 0px 0px $admin-blue;
        &:not(:first-child){
            margin-top: 10px;
        }
    }

    &__form-button{
        width: 250px;
        height: 40px;
        margin-top: 30px;
        background-color: $main;
        @include flexColumn(center);
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: white;
        border-radius: 5px;
        transition: 0.3s;
        &:hover{
            background-color: $admin-blue;
        }
    }

    &__error{
        margin-top: 10px;
        // background-color: orange;
        width: 250px;
        min-height: 30px;
        text-align: center;
        word-wrap: break-word;
    }
}

.cities-block{
    width: 100%;
    height: 100vh;
    // background-color: rgba(0, 0, 0, 0.418);
    backdrop-filter: blur(3px);
    position: absolute;
    top: 0%;
    z-index: 99;
    @include flexRow(center);
    align-items: center;
    display: none;
    z-index: 9999;

    &__container{
        max-width: 600px;
        // padding: 0px 20px 20px 20px;
        width: 100%;
        height: 500px;
        // background-color: red;
        background-color: white;
        border-radius: 10px;
        @include flexColumn(flex-start);
        overflow: hidden;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.356);
        position: relative;
    }

    &__header{
        width: calc(100% - 20px);
        height: 40px;
        padding: 10px;
        left: 0px;
        // background-color: yellow;
        background-color: $main;
        font-size: 24px;
        color: white;
        @include flexColumn(center);
    }

    &__search-bar{
        width: calc(100% - 10px);
        // width: 100%;
        height: 40px;
        // padding: 10px;
        padding: 0px 5px 0px 5px;
        border: none;
        outline: none;
        border-bottom: 1px solid $main;
    }

    &__cities-container{
        width: calc(100% - 20px);
        height: 330px;
        padding: 20px 10px 10px 10px;
        // background-color: yellow;
        @include flexRow(center);
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        overflow-y: scroll;

        &::-webkit-scrollbar{
            width: 3px;
        }

        &::-webkit-scrollbar-thumb{
            background-color: $main;
        }
    }

    &__city{
        margin: 10px;
        width: 128px;
        padding: 10px;
        // background-color: pink;
        text-align: center;
        cursor: pointer;
        border-radius: 10px;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        border: 1px solid $main;
        color: black;
        transition: 0.3s;
        @include flexRow(center);
        align-items: center;
        &:hover{
            background-color: $main;
            color: white;
        }
    }

    &__close-button{
        width: 100%;
        height: 40px;
        background-color: $main;
        cursor: pointer;
        @include flexColumn(center);
        align-items: center;
        text-align: center;
        color: white;
        transition: 0.3s;
        &:hover{
            background-color: darken($main, 5%);
        }
    }
}

@media (max-width: 1200px){
    .site-header{
        // background-color: red;
        padding: 10px 0px 10px 0px;
        height: unset;

        &__container{
            flex-wrap: wrap;
        }
        
        &__first-block{
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }

        &__logo-block{
            margin: 10px;
        }

        &__city-block{
            margin: 10px;
        }

        &__nav{
            width: 100%;
        }

        &__menu{
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__menu-item{
            margin: 5px;
        }

        // &__menu
    }
}